import React from 'react'

import 'twin.macro'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import moment from 'moment'

import { WebsiteButton as Button } from 'mx/components/section-components'
import { Section } from 'mx/section'
import { sanitizeUrl } from 'views/utils'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

const Hero = (props: {
  author: {
    avatar: any
    firstName: string
    lastName: string
  }
  category: string
  image: {
    title?: string
    description: string
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
  introduction: string
  title: string
  publishedDate: string
  publishingUrl?: string
}) => {
  return (
    <Section padding="none" tw="md:(pt-0 w-full max-w-full)">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        tw="flex w-full h-full"
      >
        <div tw="flex flex-col-reverse md:flex-row w-full justify-center items-baseline bg-black pb-20">
          <div tw="flex flex-col h-full w-full md:w-1/2 justify-center items-baseline text-left text-white px-8 lg:pl-16 pt-12 md:pt-20">
            <h1 tw="max-w-xl text-4xl font-thin leading-tight mb-8">
              {props.title}
            </h1>
            <div tw="self-stretch py-3 justify-start items-center gap-3 inline-flex">
              <div tw="px-3 py-0.5 bg-gray-200 rounded-3xl justify-center items-center gap-1 inline-flex">
                <div tw="text-gray-700 text-sm font-light font-sans leading-relaxed">
                  {props.category}
                </div>
              </div>
              <div tw="text-white text-lg font-light leading-relaxed">·</div>
              <div tw="text-gray-500 text-lg font-light font-sans leading-relaxed">
                <p tw="capitalize text-white text-base oldstyle-nums">
                  {moment(props.publishedDate).format('MMMM D, YYYY')}
                </p>
              </div>
            </div>
            <p
              tw="text-base font-light font-sans text-white mb-8 leading-relaxed max-w-xl"
              dangerouslySetInnerHTML={{
                __html: props.introduction ?? '',
              }}
            />
            <Button
              color="transparentWhite"
              rounded
              onClick={() =>
                navigate(
                  `/blog/article/${
                    props.publishingUrl || sanitizeUrl(props.title)
                  }`,
                )
              }
            >
              Read Post
            </Button>
          </div>
          <div tw="flex w-full md:w-1/2 self-center md:px-8 lg:pr-16 md:pt-20">
            <GatsbyImage
              tw="h-full max-h-[max-content]"
              title={props.image.title || ''}
              alt={props.image.description || ''}
              image={props.image.localFile?.childImageSharp?.gatsbyImageData}
            />
          </div>
        </div>
      </motion.div>
    </Section>
  )
}

export default Hero
