import { graphql, useStaticQuery } from 'gatsby'

const BLOG_PROJECT_ARTICLE_QUERY = graphql`
  query {
    content: allContentfulArticleProject {
      projectArticles: edges {
        node {
          pre
          title
          publishingUrl
          category
          author {
            avatar {
              image {
                file {
                  url
                }
              }
            }
            firstName
            lastName
          }
          date
          introduction {
            introduction
          }

          image {
            title
            description
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 1200
                  aspectRatio: 1.5
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`

export const useProjectArticleContent = () => {
  const { content } = useStaticQuery(BLOG_PROJECT_ARTICLE_QUERY)
  return content
}
