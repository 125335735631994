import React, { useState } from 'react'

import 'twin.macro'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment/moment'

import { WebsiteButton as Button } from 'mx/components/section-components'
import { ArticleItem } from 'pages/blog'
import { sanitizeUrl } from 'views/utils'

type ContractorsProps = {
  items: ArticleItem[]
}
const ArticleSnippet = ({ items }: ContractorsProps) => {
  const itemsPerPage = 6
  const lastPage = (items.length || 0) / itemsPerPage
  const [page, setPage] = useState(1)

  const loadMore = () => {
    setPage(page + 1)
  }
  return (
    <div tw="w-full max-w-screen-xl h-full max-h-full px-4 mx-auto">
      <div tw="flex items-center justify-end mt-6">
        <p tw="text-base text-gray-700">
          {items?.length} {items?.length === 1 ? 'Article' : 'Articles'}
        </p>
      </div>
      {items.length > 0 && (
        <>
          <div tw="grid grid-cols-1 gap-16 w-full md:(grid-cols-2) h-[max-content] justify-items-center">
            {items?.slice(0, itemsPerPage * page).map((item: ArticleItem) => (
              <div
                key={item.title}
                tw="w-full h-auto shadow flex-col justify-center items-center inline-flex my-6 md:my-16"
              >
                <GatsbyImage
                  tw="h-full max-h-[max-content]"
                  title={item.image?.title || ''}
                  alt={item.image?.description || ''}
                  image={
                    item.image?.localFile?.childImageSharp?.gatsbyImageData
                  }
                />
                <div tw="self-stretch h-full px-8 py-4 bg-gray-50 flex-col justify-center items-end gap-4 flex">
                  <div tw="self-stretch py-3 justify-start items-center gap-3 inline-flex">
                    <div tw="flex-col justify-center items-center gap-2 inline-flex">
                      <div tw="px-3 py-0.5 bg-gray-200 rounded-3xl justify-center items-center gap-1 inline-flex">
                        <p tw="text-gray-700 text-sm font-light leading-relaxed">
                          {item.category}
                        </p>
                      </div>
                    </div>
                    <div tw="text-gray-500 text-lg font-normal leading-relaxed">
                      ·
                    </div>
                    <div tw="text-gray-500 text-lg font-normal leading-relaxed">
                      <p tw="capitalize text-gray-500 text-base oldstyle-nums">
                        {moment(item.publishedDate).format('MMMM D, YYYY')}
                      </p>
                    </div>
                  </div>
                  <div tw="self-stretch justify-start items-center gap-8 inline-flex">
                    <h2 tw="w-full text-gray-900 text-3xl font-thin leading-tight">
                      {item.title}
                    </h2>
                    <div />
                  </div>
                  <div
                    tw="text-base leading-normal font-light text-ellipsis overflow-hidden"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.introduction ?? '',
                    }}
                  />
                  <div tw="self-stretch h-11 pt-2 flex-col justify-center items-end gap-2.5 flex">
                    <div tw="h-9 px-4 py-1 rounded-sm justify-center items-center gap-2 inline-flex">
                      <Button
                        color="transparentBlack"
                        rounded
                        onClick={() =>
                          navigate(
                            `/blog/article/${
                              item.publishingUrl || sanitizeUrl(item.title)
                            }`,
                          )
                        }
                      >
                        Read Post
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {page < lastPage && (
            <div tw="w-full flex place-content-center mb-8 md:my-6">
              <Button
                color="transparentBlack"
                rounded
                onClick={() => loadMore()}
              >
                View More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default ArticleSnippet
